import React, { useEffect, useMemo, useRef, useState } from 'react'
import './user-management-form.css'
import { startSxpProxy } from '../../../utils/api'
import {
  ADMIN_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
} from '../../../utils/constants'
import { setQueryParam } from '../../membership/membershipSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PractitionerOption } from './UserForm'
import { selectLocations } from '../../location/locationSlice'
import { toast } from 'react-toastify'
import KeycloakService from '../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../utils/roles'
import { Provenance, Practitioner } from 'fhir/r4'
import { getProvenanceData } from '../../patients/utils'
import { ProvenanceData } from '../../patients/patientsSlice'
import {
  locationtableHeadings,
  UserManagementIntent,
  UserManagementMessages,
} from '../constants'
import KeycloakRolesDialog from './KeyclockRoles'
import ProvenanceView from '../../../components/ProvenanceView'

interface UserData {
  identifier: any
  id: string
  username: string
  firstName: string
  lastName: string
  email?: string
  enabled: boolean
  createdTimestamp: any
}

const UserData = () => {
  const [usersData, setUsersData] = useState<UserData[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [editUserData, setEditUserData] = useState<{
    email: string
    firstName: string
    lastName: string
  }>({
    email: '',
    firstName: '',
    lastName: '',
  })
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [searchText, setSearchText] = useState('')
  const [operatingUnit, setOperatingUnit] = useState('')
  const [showOperatingUnit, setShowOperatingUnit] = useState(false)
  const [filterStatus, setFilterStatus] = useState<
    'all' | 'active' | 'inactive'
  >('all')
  const dispatch = useAppDispatch()
  const locations = useAppSelector(selectLocations)
  const [showFullId, setShowFullId] = useState(false)
  const VisitTableRef = useRef<HTMLDivElement | null>(null)
  const [rolesopen, setRolesOpen] = useState(false)
  const [odooUser, setOdooUser] = useState<any[]>([])
  const [fhirUser, setFhirUser] = useState<any[]>([])
  const [operatingUnitId, setOperatingUnitId] = useState('')
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const [keycloakRoles, setKeycloakRoles] = useState<PractitionerOption[]>([])
  const [patientIds, setPatientIds] = useState<string[]>([])
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  const navigate = useNavigate()
  useEffect(() => {
    fetchUsers()
    getodooUsers()
    getfhirUsers()
  }, [])

  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container?.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window?.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window?.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  const fetchUsers = () => {
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.KeyclockUsers, {})
      ?.then((response) => {
        const data = response?.data?.data
        setUsersData(data)
      })
      .catch((err) => console.error('Error fetching users:', err))
  }
  const getodooUsers = () => {
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.getOdooUsers, {})
      ?.then((response) => {
        const data = response?.data
        setOdooUser(data)
      })
      .catch((err) => console.error('Error fetching users:', err))
  }

  const getfhirUsers = () => {
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.getfhirUsers)
      ?.then((response) => {
        const data = response?.data?.entry
        setFhirUser(data)
      })
      .catch((err) => console.error('Error fetching users:', err))
  }
  const matchedUsers = useMemo(() => {
    const matchedUsersList = usersData?.filter((user) =>
      fhirUser?.some(
        (fUser) => fUser?.resource?.identifier?.[1]?.value === user?.id
      )
    )
    const matchedPatientIds = fhirUser
      ?.filter((fUser) =>
        matchedUsersList?.some(
          (user) => fUser?.resource?.identifier?.[1]?.value === user?.id
        )
      )
      ?.map((fUser) => fUser?.resource?.id)
      ?.filter((id) => id !== undefined)

    setPatientIds(matchedPatientIds)
    return matchedUsersList
  }, [usersData, fhirUser])

  useEffect(() => {
    if (patientIds?.length > 0) {
      const patientIdsString = patientIds.join(',')

      startSxpProxy(
        REGISTRATION_PROJECT_ID,
        UserManagementIntent?.fetchProvenance,
        {
          patientIds: patientIdsString,
        }
      )
        ?.then((data) => {
          const entries =
            data?.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde?.resource
            ) ?? []
          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        .catch((error) => {
          console.error('Error fetching provenance data:', error)
          toast.success(error)
        })
    }
  }, [patientIds])

  const handleModalClose = () => {
    setIsModalOpen(false)
    setSelectedUser(null)
  }

  const handleUserSearch = () => {
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.keyclockUserSearch, {
      value: searchText,
    })
      ?.then((response) => {
        const data = response?.data?.data
        const fhirFilteredData = data?.filter((user: UserData) =>
          fhirUser?.some(
            (fUser) => fUser?.resource?.identifier?.[1]?.value === user.id
          )
        )
        setUsersData(fhirFilteredData)
      })
      .catch((err) => console.error('Error fetching search results:', err))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleUserSearch()
    }
  }

  const handleCreateUser = () => {
    navigate('/administration/userCreation')
  }

  const handleClick = () => {
    setShowFullId(!showFullId)
  }

  const handleCloseDialog = () => {
    setRolesOpen(false)
  }

  const handleKeycloakRoleClick = (userId: any) => {
    setSelectedUserId(userId)
    startSxpProxy(
      ADMIN_PROJECT_ID,
      UserManagementIntent?.keyclockCompositeRoles,
      { userId }
    )
      ?.then((response) => {
        if (response?.data) {
          setKeycloakRoles(
            Array?.isArray(response?.data?.data) ? response?.data?.data : []
          )
          setRolesOpen(true)
        }
      })
      .catch((err) => console.error('Error fetching Keycloak roles:', err))
  }
  const handleGetAssginedLocation = (keyclockId: any) => {
    setSelectedUserId(keyclockId)
    startSxpProxy(
      ADMIN_PROJECT_ID,
      UserManagementIntent?.UserAssignedLocation,
      { keyclockId }
    )
      ?.then((response) => {
        const operatingUnitId = response?.data?.data?.attributes?.location?.[0]
        if (operatingUnitId) {
          const matchingLocation = locations?.find(
            (location) => location?.resource?.id === operatingUnitId
          )
          if (matchingLocation && matchingLocation?.resource.name) {
            setOperatingUnit(matchingLocation?.resource.name)
          } else {
            setOperatingUnit('No matching location name found')
          }
        } else {
          setOperatingUnit('Amalapuram')
        }
        setShowOperatingUnit(false)
        setOperatingUnitId(operatingUnitId || null)
      })
      .catch((err) => console.error('Error fetching Keycloak roles:', err))
  }

  const handlePasswordReset = () => {
    if (newPassword !== confirmPassword) {
      alert(UserManagementMessages?.PasswordIncorrect)
      return
    }
    const odooUsera = odooUser?.find(
      (user: any) =>
        user?.name?.toLowerCase() === selectedUser?.username?.toLowerCase()
    )
    handleGetAssginedLocation(selectedUserId)
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.passwordReset, {
      userId: selectedUser?.id,
      value: newPassword,
      odooId: odooUsera?.id,
      name: odooUsera?.name,
      SelectedLocation: operatingUnitId,
    })
      ?.then((data) => {
        if (data?.status === 200) {
          toast?.success(UserManagementMessages?.Done)
        }
      })
      .catch((err) => console.error('Error resetting password:', err))

    setNewPassword('')
    setConfirmPassword('')
    setIsModalOpen(false)
    setSelectedUser(null)
  }

  const filteredUsers = matchedUsers?.filter((user) => {
    switch (filterStatus) {
      case 'active':
        return user.enabled === true
      case 'inactive':
        return user.enabled === false
      default:
        return true
    }
  })
  const handleChangeStatus = (
    userId: string,
    username: string,
    active: boolean
  ) => {
    handleGetAssginedLocation(userId)
    const odooUsera = odooUser?.find(
      (user: any) => user?.name?.toLowerCase() === username?.toLowerCase()
    )
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.inactiveUser, {
      userId,
      username,
      active,
      name: odooUsera?.name,
      odooId: odooUsera?.id,
      SelectedLocation: operatingUnitId,
    })
      ?.then((response) => {
        fetchUsers()
      })
      ?.catch((err) => console.error('Error updating user status:', err))
  }
  const isUserChecked = (userId: string) => {
    return odooUser?.some((ou) => ou?.oauth_uid === userId)
  }

  const isCheckboxChecked = (userId: any) => {
    return fhirUser?.some(
      (id) => id?.resource?.identifier?.[1]?.value === userId
    )
  }

  const renderStatusSelect = (user: UserData) => {
    return (
      <select
        style={{ fontSize: '10px' }}
        className='medication-input w75'
        value={user.enabled ? 'active' : 'inactive'}
        onChange={(e) =>
          handleChangeStatus(
            user?.id,
            user?.username,
            e.target.value === 'active'
          )
        }
      >
        <option value='active'>Active</option>
        <option value='inactive'>Inactive</option>
      </select>
    )
  }

  function convertTimestamp(timestamp: any) {
    const date = new Date(timestamp)
    return date?.toLocaleString()
  }

  const handleEdit = (user: UserData) => {
    setEditDialogOpen(true)
    setEditUserData({
      email: user?.email || 'N/A',
      firstName: user?.firstName || 'N/A',
      lastName: user?.lastName || 'N/A',
    })
    setSelectedUserId(user?.id)
  }

  const handleSubmitEditedData = () => {
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.updatedataKeyclock, {
      email: editUserData?.email,
      firstName: editUserData?.firstName,
      lastName: editUserData?.lastName,
      userid: selectedUserId,
    })
      ?.then((response) => {
        if (response?.data) {
          setKeycloakRoles(
            Array?.isArray(response?.data?.data) ? response?.data?.data : []
          )
          setEditDialogOpen(false)
          toast.success(UserManagementMessages?.UserCreatedMsg)
        }
      })
      .catch((err) => {
        toast.error(UserManagementMessages?.UserErrorMsg)
      })
  }

  return (
    <div>
      <div className='test-search'>
        <div className='membershipText' style={{ marginBottom: '0rem' }}>
          Users
        </div>
        <div className='patient-search'>
          <div>
            <select
              className='category-filter'
              value={filterStatus}
              onChange={(e) =>
                setFilterStatus(e.target.value as 'all' | 'active' | 'inactive')
              }
              style={{
                borderRadius: '4px',
                backgroundColor: 'white',
              }}
            >
              <option value='all'>All</option>
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>
          <input
            title='Search by name can be partial. Please enter full text to search by Phone or Id'
            placeholder='Search by Id, Name, Phone'
            type='search'
            value={searchText}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className='search-input'
          />
          <button
            className='btn btn-primaryBtn ml10 '
            onClick={handleCreateUser}
          >
            Create
          </button>
        </div>
      </div>
      <div className='appointments-table-container' ref={VisitTableRef}>
        <table className='data-table admin-table'>
          <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
            <tr>
              <th className='table-w-4'>Sl&nbsp;No</th>
              <th className='table-w-10'>UserName</th>
              <th className='table-w-15'>Keyclock Id</th>
              <th className='table-w-10'>Name</th>
              <th className='table-w-15'>Email.Id</th>
              <th>Roles</th>
              <th>Edit</th>
              <th className='text-left table-w-2'>Reset Pwd</th>
              <th>Created On</th>
              <th>{locationtableHeadings?.UpdatedOn}</th>
              <th>THOP</th>
              <th>Odoo</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {filteredUsers?.map((user, index) => {
              const fhirData = fhirUser?.find(
                (fUser) => fUser?.resource?.identifier?.[1]?.value === user?.id
              )

              const fhirId = fhirData?.resource?.id

              return (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td className='text-left'>{user?.username}</td>
                  <td onClick={handleClick} className='userKeylockID'>
                    {showFullId ? user?.id : `${user?.id.substring(0, 15)}...`}
                  </td>
                  <td className='text-left'>
                    {user?.firstName} {user?.lastName}
                  </td>
                  <td className='text-left'>{user?.email || 'N/A'}</td>
                  <td>
                    <a
                      className='view-visit-button'
                      onClick={() => handleKeycloakRoleClick(user?.id)}
                    >
                      View
                    </a>
                  </td>
                  <td className='text-left'>
                    <a
                      className='view-visit-button'
                      onClick={() => handleEdit(user)}
                    >
                      Edit
                    </a>
                  </td>
                  <td className='text-left'>
                    <button
                      className='view-visit-button'
                      onClick={() => {
                        setSelectedUser(user)
                        setIsModalOpen(true)
                      }}
                      disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
                    >
                      Reset
                    </button>
                  </td>
                  <td className='UserDate'>
                    {convertTimestamp(user?.createdTimestamp)}
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={
                        provenances?.[fhirId]?.updatedOn ??
                        provenances?.[fhirId]?.createdOn
                      }
                      name={
                        provenances?.[fhirId]?.updatedBy ??
                        provenances?.[fhirId]?.createdBy
                      }
                      mode={'UPDATE'}
                    />
                  </td>
                  <td>
                    <input
                      type='checkbox'
                      name='THOP'
                      checked={isCheckboxChecked(user?.id)}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type='checkbox'
                      name='odoo'
                      checked={isUserChecked(user?.id)}
                      readOnly
                    />
                  </td>
                  <td>{renderStatusSelect(user)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <Dialog
        open={showOperatingUnit}
        onClose={() => setShowOperatingUnit(false)}
      >
        <DialogTitle>Operating Unit</DialogTitle>
        <DialogContent>{operatingUnit}</DialogContent>
      </Dialog>

      {selectedUser && (
        <Dialog open={isModalOpen} onClose={handleModalClose}>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                handlePasswordReset()
              }}
            >
              <TextField
                label='New Password'
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                margin='normal'
              />
              <TextField
                label='Confirm Password'
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                margin='normal'
              />
              <div className='editSubmitCancelBtn'>
                <button type='submit' className='userform-submit-btn'>
                  Submit
                </button>
                <button
                  className='userform-submit-btn'
                  onClick={handleModalClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      )}

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <TextField
              label='Email'
              type='text'
              value={editUserData?.email}
              onChange={(e) =>
                setEditUserData((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }
              fullWidth
              margin='normal'
            />
            <TextField
              label='First Name'
              type='text'
              value={editUserData?.firstName}
              onChange={(e) =>
                setEditUserData((prevState) => ({
                  ...prevState,
                  firstName: e.target.value,
                }))
              }
              fullWidth
              margin='normal'
            />
            <TextField
              label='Last Name'
              type='text'
              value={editUserData?.lastName}
              onChange={(e) =>
                setEditUserData((prevState) => ({
                  ...prevState,
                  lastName: e.target.value,
                }))
              }
              fullWidth
              margin='normal'
            />
            <div className='editSubmitCancelBtn'>
              <button
                type='submit'
                className='userform-submit-btn '
                onClick={() => handleSubmitEditedData()}
              >
                Submit
              </button>
              <button
                className='userform-submit-btn'
                onClick={() => setEditDialogOpen(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <KeycloakRolesDialog
        isOpen={rolesopen}
        onClose={handleCloseDialog}
        roles={keycloakRoles}
        userId={selectedUserId}
      />
    </div>
  )
}

export default UserData
