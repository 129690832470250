import { useNavigate } from 'react-router-dom'
import { Box, Button, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PractitionerRole } from 'fhir/r4'
import { useState } from 'react'

import { toast } from 'react-toastify'
import { startSxpProxy } from '../../utils/api'
import { ADMIN_PROJECT_ID } from '../../utils/constants'
import KeycloakService from '../../utils/keycloakService'
import { FormDoctor } from '../administration/doctor/DoctorsForm'
import Asterisk from '../../components/Asterisk'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { emptyString } from '../Radiology/constants'
import {
  CreatePartnerForm,
  doctor,
  forms,
  formValues,
  OrganizationIntent,
  tableText,
} from '../administration/constants'

const styles = (theme: any) => ({})
const formatDoctors = (
  roles: { resource: PractitionerRole }[],
  id?: string
) => {
  if (!id) {
    return 'NA'
  }
  const resType = roles.find(
    (e) => e?.resource?.practitioner?.reference?.split?.('/')?.[1] === id
  )
  const code =
    resType?.resource?.specialty?.[0]?.coding?.[0]?.code ?? emptyString
  const display =
    resType?.resource?.specialty?.[0]?.coding?.[0]?.display ?? emptyString
  return `${display}::${code}`
}

const PartnerForm = ({
  partner,
  isEdit,
}: {
  partner?: any
  isEdit?: boolean
}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [doctorsData, setDoctorsData] = useState<FormDoctor[]>([])
  const classes = useCustomStyles(styles, theme)
  const initialFormState = {
    type: 'b2bPartner',
    name: '',
    phoneNumber: '',
    status: '',
    fhirId: '',
    id: '',
    govId: '',
  }
  const [partnerData, setPartnerData] = useState(partner ?? initialFormState)
  const {
    values,
    setFieldValue,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    submitForm,
  } = useFormik({
    initialValues: partnerData,
    validationSchema: Yup.object({}),
    onSubmit: (values, { setSubmitting }) => {
      handleRegistrationSubmit(values, setSubmitting)
    },
    enableReinitialize: true,
  })
  const handleRegistrationSubmit = async (
    data: Record<string, any>,
    setSubmitting: any
  ) => {
    const { type, name, phoneNumber, email, status, fhirId, id } = data
    const formatted = {
      type: 'b2bPartner',
      name: name,
      mobile_number: phoneNumber,
      fhir_id: fhirId,
      ...(id ? {} : { created_by: KeycloakService?.getUsername() }),
      updated_by: KeycloakService?.getUsername(),
      ...(id && { id: id }),
      status: id ? data.status : formValues?.active,
      ...(id && { odoo_partnerId: values?.odoo_partner_id }),
      ...(id && { date_changed: new Date()?.toISOString() }),
    }

    const state = {
      organization: formatted,
    }
    const operation = id
      ? OrganizationIntent?.editOrganization
      : OrganizationIntent?.createOrganization
    const statebody = id ? formatted : state
    startSxpProxy(ADMIN_PROJECT_ID, operation, statebody)
      ?.then((data) => {
        toast?.success(data?.data?.message)
        navigate('/administration/referredOut')
      })
      .catch((err) => {
        toast.error(data?.data?.message)
      })
  }

  const handleDoctorChange = (e: { target: { value: string } }) => {
    const selectedDoctor = doctorsData?.find(
      (doc) => doc?.id === e?.target?.value
    )
    if (selectedDoctor) {
      setValues({
        ...values,
        fhirId: selectedDoctor?.id,
        name: `${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`,
        phoneNumber: selectedDoctor?.phone,
        status: selectedDoctor?.active
          ? formValues?.active
          : formValues?.inActive,
      })
    } else {
      setFieldValue(tableText?.name, e.target.value)
    }
  }

  return (
    <>
      <div>
        <form className='panel-form'>
          <div className='admin-form-flex w-72'>
            {values.type === doctor ? (
              <div className='doc-container'>
                <label htmlFor='doctor-name' className='doc-input-label'>
                  {CreatePartnerForm?.Name}
                  <Asterisk />
                  &nbsp;:
                </label>
                <select
                  className='panel-input-content'
                  required
                  name='id'
                  value={values?.fhirId}
                  onChange={handleDoctorChange}
                >
                  <option value={emptyString}>Select Doctor</option>
                  {doctorsData?.map((doctor) => (
                    <option key={doctor?.id} value={doctor?.id}>
                      {doctor?.firstName} {doctor?.lastName}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className='doc-container'>
                <label htmlFor='test-name' className='doc-input-label'>
                  {CreatePartnerForm?.Name}
                  <Asterisk />
                  &nbsp;:
                </label>
                <input
                  type='text'
                  className='panel-input-content'
                  required
                  name='name'
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            )}
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                {CreatePartnerForm?.PhoneNumber}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='phoneNumber'
                value={values?.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            {isEdit && (
              <div className='doc-container'>
                <label htmlFor='test-name' className='doc-input-label'>
                  {CreatePartnerForm?.Status}
                  <Asterisk />
                  &nbsp;:
                </label>
                <select
                  className='panel-input-content'
                  required
                  name='status'
                  value={values?.status}
                  onChange={handleChange}
                >
                  <option value={emptyString}>
                    {CreatePartnerForm?.SelectOption}
                  </option>
                  <option value={forms?.active}>{forms?.active}</option>
                  <option value={forms?.inactive}>{forms?.inactive}</option>
                </select>
              </div>
            )}
            <div className='doc-container'>
              <div className='doc-input-label'></div>
              <div className='panel-input-content'>
                <Box className='box-btn-referred'>
                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    className='ip-register-btn w10'
                    onClick={submitForm}
                  >
                    {CreatePartnerForm?.Submit}
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
export default PartnerForm
