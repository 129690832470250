import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  addDays,
  durationUnits,
  formatDate,
  frequencyOptions,
  instructions,
  routeOptions,
  unitOptions,
  FormData,
} from './medicationModels'
import { calculateFreq } from './utils'
import { startSxpProxy } from '../../../../utils/api'
import { IPD_PROJECT_ID, OPD_PROJECT_ID } from '../../../../utils/constants'
import { Drug, Medication } from '../../models'
import {
  Button,
  Autocomplete,
  TextField,
  List,
  Paper,
  Box,
} from '@mui/material'
import { styled } from '@mui/system'
import { toast } from 'react-toastify'
import { capitalizeFirstLetter } from '../../../patients/utils'
import dayjs, { Dayjs } from 'dayjs'
import DatePicker from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import isoWeek from 'dayjs/plugin/isoWeek'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useAppSelector } from '../../../../app/hooks'
import { selectSelectedLocationId } from '../../../location/locationSlice'
dayjs.extend(isoWeek)

const StyledPaper = styled(Paper)(() => ({
  '& .MuiAutocomplete-listbox': {
    fontSize: '12px',
  },
}))

const StyledList = styled(List)(() => ({
  '& .MuiAutocomplete-option': {
    fontSize: '12px',
  },
}))

type Props = {
  drugName: string
  id: any
  setReload: any
  medications: Medication[]
  onItemDelete: (id: number) => void
}

const MedicationForm = ({
  medications,
  setReload,
  id,
  onItemDelete,
}: Props) => {
  const autocompleteRef = useRef<HTMLDivElement | null>(null)
  const [searchText, setSearchText] = useState('')
  const [drugList, setDrugList] = useState<any[]>([])
  const [openList, setOpenList] = useState(false)
  const [alternativeDisabled, setAlternativeDisabled] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    drugName: searchText,
    dose: '',
    unit: '',
    frequency: '',
    route: '',
    duration: '',
    radioOptions: [],
    startDate: '',
    endDate: '',
    durationUnits: '',
    quantities: '',
    quantityUnits: '',
    sos: false,
    instructions: '',
    additional: '',
  })
  const [status, setStatus] = useState('')
  const locationId = useAppSelector(selectSelectedLocationId)
  const handleSearch = (value: string) => {
    const intent = 'getDrugsByName'
    const state = {
      name: value,
      location: locationId,
    }
    setAlternativeDisabled(false)
    startSxpProxy(OPD_PROJECT_ID, intent, state)
      .then((resp) => {
        const data = resp?.data as Drug[]
        if (data?.length) {
          setDrugList(
            data?.map((drug) => ({
              label: (
                <div>
                  <div>{drug?.name}</div>
                  <div>Qty-{drug?.qty_available}</div>
                  <div className='druglist-composition'>
                    Composition: {drug?.salt_composition}
                  </div>
                </div>
              ),
              value: drug?.name,
            }))
          )
        } else {
          setDrugList([])
        }
      })
      .catch(() => setDrugList([]))
  }
  const handleInputChange = (value: string) => {
    setSearchText(value)
    handleSearch(value)
  }
  const handleSelectChange = (selectedOption: any) => {
    setSearchText(selectedOption ? selectedOption?.value : '')
    setFormData({
      ...formData,
      drugName: selectedOption ? selectedOption.value : '',
    })
  }

  const handleTimeChange = (name: string, value: Dayjs | null) => {
    setFormData((prevState) => ({
      ...prevState,
      radioOptions: [
        {
          ...prevState?.radioOptions[0],
          [name]: dayjs(value).format('HH:mm:ssZ'),
        },
      ],
    }))
  }

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name, checked } = e.target
    const newstate = formData

    const updatedOptions = checked
      ? [...newstate.radioOptions, { name, value }]
      : newstate.radioOptions.filter(
          (option) => !(option.name === name && option.value === value)
        )

    if (newstate?.frequency == 'Once a day' && updatedOptions.length > 1) {
      toast.error('Frequency selected as ONCE A DAY')
    } else if (
      newstate?.frequency == 'Twice a day' &&
      updatedOptions.length > 2
    ) {
      toast.error('Frequency selected as TWICE A DAY')
    } else if (
      newstate?.frequency == 'Thrice a day' &&
      updatedOptions.length > 3
    ) {
      toast.error('Frequency selected as Thrice A DAY')
    } else if (
      newstate?.frequency == 'Four times a day' &&
      updatedOptions.length > 4
    ) {
      toast.error('Frequency selected as FOUR TIMES A DAY')
    } else if (
      newstate?.frequency == 'Five times a day' &&
      updatedOptions.length > 5
    ) {
      toast.error('Frequency selected as Five TIMES A DAY')
    } else if (
      newstate?.frequency == 'Once a week' &&
      updatedOptions.length > 1
    ) {
      toast.error('Frequency selected as Once A Week')
    } else if (
      newstate?.frequency == 'Twice a week' &&
      updatedOptions.length > 2
    ) {
      toast.error('Frequency selected as Twice A Week')
    } else if (
      newstate?.frequency == 'Thrice a week' &&
      updatedOptions.length > 3
    ) {
      toast.error('Frequency selected as Three Days A Week')
    } else if (
      newstate?.frequency == 'Four days a week' &&
      updatedOptions.length > 4
    ) {
      toast.error('Frequency selected as Four  A Week')
    } else if (
      newstate?.frequency == 'Five days a week' &&
      updatedOptions.length > 5
    ) {
      toast.error('Frequency selected as Five Days A Week')
    } else if (
      newstate?.frequency == 'Six days a week' &&
      updatedOptions.length > 6
    ) {
      toast.error('Frequency selected as Six Days A Week')
    } else {
      setFormData({ ...newstate, radioOptions: updatedOptions })
    }
  }
  const handleNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target

    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        [name]: value,
      }

      if (name === 'unit') {
        newFormData.quantityUnits = value
      }
      if (name === 'dose' && parseInt(value) <= 0) {
        return prevFormData
      }

      if (name === 'duration' || name === 'frequency' || name === 'dose') {
        const frequencies = calculateFreq(
          newFormData.frequency,
          parseInt(newFormData.dose),
          newFormData.duration,
          newFormData?.radioOptions?.length
        )
        newFormData.durationUnits = frequencies?.unitsOne ?? ''
        newFormData.quantities =
          newFormData?.unit === 'tablet' || newFormData?.unit === 'capsule'
            ? frequencies?.quantities ?? ''
            : ''
        if (name === 'frequency') {
          Object.assign(newFormData, {
            radioOptions: [],
            startDate: '',
            endDate: '',
            duration: '',
          })
        }
      }

      if (name === 'startDate' && value) {
        const days =
          newFormData.frequency == 'On alternate days'
            ? Number(newFormData?.duration) * 2
            : newFormData.durationUnits == 'week'
            ? Number(newFormData?.duration) * 7
            : newFormData.durationUnits == 'day'
            ? Number(newFormData?.duration)
            : newFormData.durationUnits == 'month'
            ? Number(newFormData?.duration) * 30
            : newFormData.durationUnits == '2 week'
            ? Number(newFormData?.duration) * 17
            : newFormData.durationUnits == 'month'
            ? Number(newFormData?.duration) * 30
            : 1

        const parsedDate = new Date(value)
        const newDate = addDays(parsedDate, days)
        const formatted = formatDate(newDate)
        newFormData.endDate = formatted
      }

      // if (name === 'frequency') {
      //   newFormData.radioOptions = []
      //   if (newFormData?.durationUnits == 'hour') {
      //     newFormData.radioOptions = [
      //       {
      //         name: 'starting from',
      //         value: `starting from ${getTime()}`,
      //       },
      //     ]
      //   }
      // }

      return newFormData
    })
  }

  const handleSosChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
      instructions: checked ? prevFormData.instructions : '',
      additional: checked ? prevFormData.additional : '',
    }))
  }
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (
      formData?.frequency == 'On alternate days' &&
      formData.radioOptions.length <= 0
    ) {
      toast.error('Please select atleast one date')
      return
    }
    if (
      formData?.frequency == 'Once a day' &&
      formData.radioOptions.length < 1
    ) {
      toast.error('Please select Atleat one frequency')
      return
    } else if (
      formData?.frequency == 'Twice a day' &&
      formData.radioOptions.length < 2
    ) {
      toast.error('Please select two frequencies')
      return
    } else if (
      formData?.frequency == 'Thrice a day' &&
      formData.radioOptions.length < 3
    ) {
      toast.error('Please select three frequencies')
      return
    } else if (
      formData?.frequency == 'Four times a day' &&
      formData.radioOptions.length < 4
    ) {
      toast.error('Please select Four frequencies')
      return
    } else if (
      formData?.frequency == 'Five times a day' &&
      formData.radioOptions.length < 5
    ) {
      toast.error('Please select Five frequencies')
      return
    } else if (
      formData?.frequency == 'Once a week' &&
      formData.radioOptions.length < 1
    ) {
      toast.error('Please select One Day')
      return
    } else if (
      formData?.frequency == 'Twice a week' &&
      formData.radioOptions.length < 2
    ) {
      toast.error('Please select two Days')
      return
    } else if (
      formData?.frequency == 'Thrice a week' &&
      formData.radioOptions.length < 3
    ) {
      toast.error('Please select Three Days')
      return
    } else if (
      formData?.frequency == 'Four days a week' &&
      formData.radioOptions.length < 4
    ) {
      toast.error('Please select Four Days')
      return
    } else if (
      formData?.frequency == 'Five days a week' &&
      formData.radioOptions.length < 5
    ) {
      toast.error('Please select Five Days')
      return
    } else if (
      formData?.frequency == 'Six days a week' &&
      formData.radioOptions.length < 6
    ) {
      toast.error('Please select Six Days')
      return
    }

    const state = {
      additional: formData.additional,
      drugName: formData.drugName,
      dose: formData.dose,
      unit: formData.unit,
      frequency: formData.frequency,
      instructions: formData.instructions,
      quantityUnits: formData.quantityUnits,
      route: formData.route,
      sos: formData.sos,
      startDate: formData.startDate,
      quantities: formData.quantities,
      visitId: id,
      duration: formData.duration,
      durationUnits: formData.durationUnits,
      intervals: formData.radioOptions,
      endDate: formData.endDate,
    }
    startSxpProxy(OPD_PROJECT_ID, 'addMedication', state)
      .then((data) => {
        setReload((prev: any) => !prev)
        setFormData({
          drugName: '',
          dose: '',
          unit: '',
          frequency: '',
          route: '',
          duration: '',
          radioOptions: [],
          startDate: '',

          endDate: '',
          durationUnits: '',
          quantities: '',
          quantityUnits: '',
          sos: false,
          instructions: '',
          additional: '',
        })
      })
      .catch((err) => console.error(err, 'err'))
  }

  const getCurrentDate = () => {
    const current = new Date()
    const year = current.getFullYear()
    const month = String(current.getMonth() + 1).padStart(2, '0')
    const day = String(current.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  useEffect(() => {
    startSxpProxy(IPD_PROJECT_ID, 'getEncounterById', { encounterId: id }).then(
      (data) => {
        setStatus(data?.data?.statusHistory?.[0]?.status)
      }
    )
  }, [id])

  const sosCheck = () => {
    if (formData.sos) {
      if (formData.instructions.length > 0 && formData.additional.length > 0) {
        return false
      } else {
        return true
      }
    }
  }

  const handleAlternate = async (name: any) => {
    try {
      const intent = 'getDrugsByComposition'
      const state = {
        name: name,
        location: locationId,
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      const data = (await resp?.data) as Drug[]

      if (data?.length > 0) {
        setDrugList(
          data?.map((drug) => ({
            label: (
              <div>
                <div>{drug?.name}</div>
                <div>Qty-{drug?.quantity}</div>
                <div className='druglist-composition'>
                  Composition: {drug?.composition}
                </div>
              </div>
            ),
            value: drug?.name,
          }))
        )
        setAlternativeDisabled(true)
        setOpenList(true)
        const closeButton = autocompleteRef.current?.querySelector(
          'button[aria-label="Clear"]'
        ) as HTMLButtonElement | null
        if (closeButton) {
          closeButton.click()
        }
      } else {
        setDrugList([])
      }
    } catch (error) {
      console.error('Error fetching drugs:', error)
      setDrugList([])
    }
  }

  const handleOpen = () => {
    setOpenList(true)
  }

  const handleClose = () => {
    setOpenList(false)
  }

  const sortDatesAndSetRange = (dates: string[], frequency: string) => {
    if (!dates || dates.length === 0) {
      toast.error('No dates provided or dates array is empty')
      return
    }
    const sortedDates = dates?.sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    )
    const startDate = sortedDates[0]
    const endDate = sortedDates[sortedDates.length - 1]
    setFormData((prevState) => ({
      ...prevState,
      startDate: startDate,
      endDate: endDate,
      duration: handleDurationForWeeks(
        frequency,
        dates?.length,
        startDate,
        endDate
      ),
    }))
  }

  const getWeekCount = (startDate: string, endDate: string): number => {
    const startMoment = dayjs(startDate)
    const endMoment = dayjs(endDate)
    const weeks = endMoment.diff(startMoment, 'week')
    return weeks + 1
  }

  const handleDurationForWeeks = (
    frequency: string,
    datesLength: number,
    startDate: string,
    endDate: string
  ) => {
    switch (frequency) {
      case 'On alternate days':
        return datesLength.toString()
      case 'Once a week':
        return datesLength.toString()
      case 'Twice a week':
        return getWeekCount(startDate, endDate).toString()
      case 'Thrice a week':
        return getWeekCount(startDate, endDate).toString()
      case 'Four days a week':
        return getWeekCount(startDate, endDate).toString()
      case 'Five days a week':
        return getWeekCount(startDate, endDate).toString()
      case 'Six days a week':
        return getWeekCount(startDate, endDate).toString()
      default:
        return ''
    }
  }

  const handleDisableDates = (
    date: any,
    selectedDates: any,
    frequency: string
  ) => {
    switch (frequency) {
      case 'On alternate days':
        return disableAlternativeDays(date, selectedDates)
      case 'Once a week':
        return disableWeeks(date, selectedDates, 1)
      case 'Twice a week':
        return disableWeeks(date, selectedDates, 2)
      case 'Thrice a week':
        return disableWeeks(date, selectedDates, 3)
      case 'Four days a week':
        return disableWeeks(date, selectedDates, 4)
      case 'Five days a week':
        return disableWeeks(date, selectedDates, 5)
      case 'Six days a week':
        return disableWeeks(date, selectedDates, 6)
      default:
        break
    }
  }

  const disableAlternativeDays = (date: any, selectedDates: any) => {
    const isSelected = selectedDates?.some(
      (selectedDate: any) =>
        dayjs(date).format('YYYY-MM-DD') ===
        dayjs(selectedDate).format('YYYY-MM-DD')
    )
    if (isSelected) {
      return false
    }
    return selectedDates?.some((selectedDate: any) => {
      const dayDifference = dayjs(date)?.diff(dayjs(selectedDate), 'day')
      return dayDifference % 2 !== 0
    })
  }

  const disableWeeks = (
    date: any,
    selectedDates: any,
    disableFrequency: number
  ) => {
    const weekOfYear = dayjs(date).isoWeek()
    const isSelected = selectedDates?.some(
      (selectedDate: any) =>
        dayjs(date).format('YYYY-MM-DD') ===
        dayjs(selectedDate).format('YYYY-MM-DD')
    )
    if (isSelected) {
      return false
    }
    const selectedInSameWeek = selectedDates.filter(
      (selectedDate: any) => dayjs(selectedDate).isoWeek() === weekOfYear
    ).length

    return selectedInSameWeek >= disableFrequency
  }

  const renderTimes = (startTime: Dayjs | null, disable: boolean) => {
    return (
      <div className='drug-flexRow'>
        <div className='drug-flexRows gap7'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              disabled={disable}
              value={startTime}
              onChange={(newValue) => handleTimeChange('startTime', newValue)}
              className='time-picker-medications'
              slotProps={{
                textField: { placeholder: 'Start Time' },
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
    )
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className='drugTable'>
          <table className='data-table table-fixed w155'>
            <thead
              className='drug-header'
              style={{ backgroundColor: '#4682b4' }}
            >
              <tr>
                <th className='w9' style={{ width: '16%' }}>
                  Medicine Name
                </th>
                <th className='w3'>Dose</th>
                <th className='w6'>Unit</th>
                <th className='w8'>Route</th>
                <th className='w7'>Frequency</th>
                <th className='w13'>Interval</th>
                <th className='w10'>Duration/Units</th>
                <th className='w4'>Total Qty</th>
                <th className='w7'>St Date</th>
                <th className='w7'>End Date</th>
                <th colSpan={3} className='w16'>
                  SOS/Instructions
                </th>
                <th className='w5'>Action</th>
              </tr>
            </thead>
            <tbody>
              {medications.length > 0 &&
                medications.map((med: Medication, i: number) => (
                  <tr key={i}>
                    <td>{med?.drug_name}</td>
                    <td>{med?.dose}</td>
                    <td>{capitalizeFirstLetter(med?.dose_units)}(s)</td>
                    <td>{capitalizeFirstLetter(med?.route)}</td>
                    <td>{med?.frequency}</td>
                    <td>
                      {med.intervals &&
                        (med.frequency === 'Once a day' ||
                          med.frequency === 'Immediately' ||
                          med.frequency === 'Twice a day' ||
                          med.frequency === 'Thrice a day' ||
                          med.frequency === 'Four times a day' ||
                          med.frequency === 'Five times a day') && (
                          <>
                            <div className='drug-flexRow gap15'>
                              <div className='drug-flexColumn'>
                                <label>Breakfast</label>
                                <div className='drug-flexRow gap18'>
                                  <input
                                    type='checkbox'
                                    name='breakfast'
                                    value='bb'
                                    readOnly
                                    className='medication-input'
                                    checked={med?.intervals?.some(
                                      (option) =>
                                        option.name === 'breakfast' &&
                                        option.value === 'bb'
                                    )}
                                  />
                                  <input
                                    className='ml20'
                                    readOnly
                                    type='checkbox'
                                    name='breakfast'
                                    value='ab'
                                    checked={med?.intervals?.some(
                                      (option) =>
                                        option.name === 'breakfast' &&
                                        option.value === 'ab'
                                    )}
                                  />
                                </div>
                              </div>

                              <div className='drug-flexColumn ml10'>
                                <label>Lunch</label>
                                <div className='drug-flexRow gap17'>
                                  <input
                                    type='checkbox'
                                    name='before lunch'
                                    readOnly
                                    value='bl'
                                    checked={med?.intervals?.some(
                                      (option) =>
                                        option.name === 'before lunch' &&
                                        option.value === 'bl'
                                    )}
                                  />
                                  <input
                                    type='checkbox'
                                    name='after lunch'
                                    readOnly
                                    value='al'
                                    checked={med?.intervals?.some(
                                      (option) =>
                                        option.name === 'after lunch' &&
                                        option.value === 'al'
                                    )}
                                  />
                                </div>
                              </div>

                              <div className='drug-flexColumn ml10'>
                                <label>Dinner</label>
                                <div className='drug-flexRow gap19'>
                                  <input
                                    type='checkbox'
                                    readOnly
                                    name='before dinner'
                                    value='bd'
                                    checked={med?.intervals?.some(
                                      (option) =>
                                        option.name === 'before dinner' &&
                                        option.value === 'bd'
                                    )}
                                  />
                                  <input
                                    type='checkbox'
                                    name='after dinner'
                                    readOnly
                                    value='ad'
                                    checked={med?.intervals?.some(
                                      (option) =>
                                        option.name === 'after dinner' &&
                                        option.value === 'ad'
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                      {med.intervals &&
                        (med.frequency === 'On alternate days' ||
                          med.frequency === 'Once a week' ||
                          med.frequency === 'Twice a week' ||
                          med.frequency === 'Thrice a week' ||
                          med.frequency === 'Four days a week' ||
                          med.frequency === 'Five days a week' ||
                          med.frequency === 'Six days a week') && (
                          <div className='multi-date-picker-conatiner'>
                            <DatePicker
                              key={1}
                              multiple
                              format='DD/MM/YYYY'
                              plugins={[<DatePanel key={2} />]}
                              placeholder='Select Dates'
                              containerClassName='custom-container-multi-date-picker'
                              value={med?.intervals?.map((dateString) =>
                                dayjs(dateString, 'YYYY-MM-DD').toDate()
                              )}
                            />
                          </div>
                        )}
                      {med?.intervals && med?.duration_units == 'hour' && (
                        <div className='drug-flexRow gap10'>
                          <div className='drug-flexColumn gap10'>
                            {med?.intervals[0]?.value}
                          </div>
                        </div>
                      )}
                      {med?.intervals &&
                        (med?.frequency === 'Every Hour' ||
                          med?.frequency === 'Every 2 hours' ||
                          med?.frequency === 'Every 3 hours' ||
                          med?.frequency === 'Every 4 hours' ||
                          med?.frequency === 'Every 6 hours' ||
                          med?.frequency === 'Every 8 hours' ||
                          med?.frequency === 'Every 12 hours') &&
                        renderTimes(
                          dayjs(med?.intervals[0]?.startTime, 'HH:mm:ssZ') ||
                            null,
                          true
                        )}
                    </td>
                    <td>
                      <div className='drug-flexRow border-none gap17 mt7'>
                        <div>{med?.duration}</div>
                        <div>
                          {capitalizeFirstLetter(med?.duration_units)}(s)
                        </div>
                      </div>
                    </td>
                    <td>{med?.total_quantity}</td>
                    <td>{med?.start_date}</td>
                    <td>{med?.next_visit_date ?? '-'}</td>
                    <td colSpan={3}>
                      <div className='border-none'>
                        <div className='drug-flexRow gap10'>
                          <div>{med?.sos ? 'Yes' : 'No'}</div>
                          <div>
                            {med?.sos && <div>{med?.instructions}</div>}
                            <div>
                              {med?.sos && (
                                <div>{med?.additional_instructions}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <Button
                        sx={{
                          textTransform: 'none',
                          backgroundColor: '#e9ecef',
                          fontSize: '12px !important',
                        }}
                        className='filter-button'
                        onClick={() => onItemDelete(med?.id)}
                        disableRipple
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              <tr key={medications.length + 1}>
                <td>
                  <div style={{ zIndex: 1 }}>
                    <Autocomplete
                      ref={autocompleteRef}
                      options={drugList}
                      open={openList}
                      onOpen={handleOpen}
                      onClose={handleClose}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      getOptionLabel={(option) => option?.value}
                      onChange={(_, newValue) => handleSelectChange(newValue)}
                      noOptionsText={
                        searchText?.length === 0
                          ? 'Enter Some Text'
                          : 'No Options'
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size='small'
                          placeholder='Select or search drug'
                          value={searchText}
                          onChange={(e) => handleInputChange(e.target.value)}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box
                          component='li'
                          {...props}
                          display='flex'
                          flexDirection='column'
                          alignItems='center'
                        >
                          <Box>{option?.label}</Box>
                          <Box display='flex'>
                            <Button
                              size='small'
                              onClick={() => handleSelectChange(option)}
                            >
                              Order
                            </Button>
                            <Button
                              size='small'
                              disabled={alternativeDisabled}
                              onClick={() => handleAlternate(option?.value)}
                            >
                              Search Alternative
                            </Button>
                          </Box>
                        </Box>
                      )}
                      PaperComponent={(props) => <StyledPaper {...props} />}
                      ListboxComponent={(props) => <StyledList {...props} />}
                      sx={{
                        '& .MuiAutocomplete-input': {
                          fontSize: '12px',
                        },
                        '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
                          fontSize: '12px',
                        },
                        '& .MuiAutocomplete-endAdornment': {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                        '& .MuiAutocomplete-endAdornment button': {
                          padding: 'unset',
                        },
                      }}
                    />
                  </div>
                </td>
                <td className='padding-none'>
                  <input
                    required
                    name='dose'
                    type='number'
                    className='medication-input w49'
                    placeholder='dose'
                    value={formData.dose}
                    onChange={handleNameChange}
                    min={1}
                  />
                </td>
                <td>
                  <select
                    name='unit'
                    value={formData.unit}
                    className='medication-input w88 border-none'
                    onChange={handleNameChange}
                  >
                    <option value=''>Select</option>
                    {unitOptions.map((op: any) => (
                      <option
                        className='option-content'
                        key={op.value}
                        value={op.value}
                      >
                        {op.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    name='route'
                    className='medication-input w108 border-none'
                    value={formData.route}
                    onChange={handleNameChange}
                  >
                    <option value=''>Select</option>
                    {routeOptions.map((op: any) => (
                      <option
                        className='option-content'
                        key={op.value}
                        value={op.value}
                      >
                        {op.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    name='frequency'
                    value={formData?.frequency}
                    onChange={handleNameChange}
                    className='medication-input w94 border-none'
                  >
                    <option value=''>Select</option>
                    {frequencyOptions?.map((op: any) => (
                      <option
                        className='option-content'
                        key={op?.value}
                        value={op?.value}
                      >
                        {op?.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <div className='drug-flexRow gap10'>
                    {formData.frequency === '' && (
                      <div>
                        <span>No Options Found</span>
                      </div>
                    )}

                    {formData.frequency === 'Once a day' ||
                    formData.frequency === 'Twice a day' ||
                    formData.frequency === 'Thrice a day' ||
                    formData.frequency === 'Five times a day' ||
                    formData.frequency === 'Four times a day' ? (
                      <>
                        <div className='drug-flexColumn'>
                          <label>Breakfast</label>
                          <div className='drug-flexRow gap18'>
                            <input
                              type='checkbox'
                              name='breakfast'
                              value='bb'
                              className='medication-input'
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === 'breakfast' &&
                                  option.value === 'bb'
                              )}
                              onChange={handleRadioChange}
                            />
                            <input
                              className='ml20'
                              type='checkbox'
                              name='breakfast'
                              value='ab'
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === 'breakfast' &&
                                  option.value === 'ab'
                              )}
                              onChange={handleRadioChange}
                            />
                          </div>
                        </div>

                        <div className='drug-flexColumn ml10'>
                          <label>Lunch</label>
                          <div className='drug-flexRow gap17'>
                            <input
                              type='checkbox'
                              name='before lunch'
                              value='bl'
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === 'before lunch' &&
                                  option.value === 'bl'
                              )}
                              onChange={handleRadioChange}
                            />
                            <input
                              type='checkbox'
                              name='after lunch'
                              value='al'
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === 'after lunch' &&
                                  option.value === 'al'
                              )}
                              onChange={handleRadioChange}
                            />
                          </div>
                        </div>

                        <div className='drug-flexColumn ml10'>
                          <label>Dinner</label>
                          <div className='drug-flexRow gap19'>
                            <input
                              type='checkbox'
                              name='before dinner'
                              value='bd'
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === 'before dinner' &&
                                  option.value === 'bd'
                              )}
                              onChange={handleRadioChange}
                            />
                            <input
                              type='checkbox'
                              name='after dinner'
                              value='ad'
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === 'after dinner' &&
                                  option.value === 'ad'
                              )}
                              onChange={handleRadioChange}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}

                    {(formData.frequency === 'On alternate days' ||
                      formData.frequency === 'Once a week' ||
                      formData.frequency === 'Twice a week' ||
                      formData.frequency === 'Thrice a week' ||
                      formData.frequency === 'Four days a week' ||
                      formData.frequency === 'Five days a week' ||
                      formData.frequency === 'Six days a week') && (
                      <div className='multi-date-picker-conatiner'>
                        <DatePicker
                          key={1}
                          multiple
                          format='YYYY-MM-DD'
                          plugins={[<DatePanel key={2} />]}
                          placeholder='Select Dates'
                          containerClassName='custom-container-multi-date-picker'
                          value={formData?.radioOptions}
                          onChange={(values) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              radioOptions: values?.map((date) =>
                                date.format('YYYY-MM-DD')
                              ),
                              quantities:
                                formData?.unit === 'tablet' ||
                                formData?.unit === 'capsule'
                                  ? (
                                      parseInt(prevState?.dose) * values?.length
                                    ).toString()
                                  : '',
                            }))
                            sortDatesAndSetRange(
                              values?.map((date) => date.format('YYYY-MM-DD')),
                              formData?.frequency
                            )
                          }}
                          mapDays={({ date }) => {
                            const isDisabled = handleDisableDates(
                              date,
                              formData?.radioOptions,
                              formData?.frequency
                            )
                            return {
                              disabled: isDisabled,
                            }
                          }}
                        />
                      </div>
                    )}

                    {(formData?.frequency === 'Every Hour' ||
                      formData?.frequency === 'Every 2 hours' ||
                      formData?.frequency === 'Every 3 hours' ||
                      formData?.frequency === 'Every 4 hours' ||
                      formData?.frequency === 'Every 6 hours' ||
                      formData?.frequency === 'Every 8 hours' ||
                      formData?.frequency === 'Every 12 hours') &&
                      renderTimes(
                        formData?.radioOptions[0]?.startTime
                          ? dayjs(
                              formData?.radioOptions[0]?.startTime,
                              'HH:mm:ssZ'
                            )
                          : null,
                        false
                      )}
                  </div>
                </td>
                <td className='drug-flexRows border-none '>
                  <input
                    required
                    name='duration'
                    type='number'
                    className='medication-input w72 border-none'
                    placeholder='duration'
                    value={formData.duration}
                    onChange={handleNameChange}
                    min={1}
                  />
                  <select
                    // disabled={true}
                    name='durationUnits'
                    value={formData.durationUnits}
                    onChange={handleNameChange}
                    className='medication-input w75'
                  >
                    <option value=''>Select</option>
                    {durationUnits?.map((op: any) => (
                      <option
                        className='option-content'
                        key={op?.value}
                        value={op?.value}
                      >
                        {op?.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    required
                    name='quantities'
                    type='number'
                    className='medication-input w50 border-none'
                    placeholder='quantities'
                    value={formData.quantities}
                    onChange={handleNameChange}
                    min={1}
                  />
                </td>
                <td>
                  <input
                    min={getCurrentDate()}
                    required
                    name='startDate'
                    type='text'
                    className='medication-input'
                    placeholder='select date'
                    onFocus={(e) => {
                      e.currentTarget.type = 'date'
                    }}
                    onBlur={(e) => {
                      e.currentTarget.type = e.target.value ? 'date' : 'text'
                    }}
                    value={formData.startDate}
                    onChange={handleNameChange}
                  />
                </td>
                <td>
                  <input
                    required
                    name='endDate'
                    type='text'
                    className='medication-input border-none'
                    placeholder='End Date'
                    onFocus={(e) => {
                      e.currentTarget.type = 'date'
                    }}
                    onBlur={(e) => {
                      e.currentTarget.type = e.target.value ? 'date' : 'text'
                    }}
                    value={formData.endDate}
                    onChange={handleNameChange}
                  />
                </td>
                <td colSpan={3}>
                  <>
                    <div className='drug-flexRow gap10'>
                      <div>
                        <input
                          type='checkbox'
                          name='sos'
                          checked={formData.sos}
                          onChange={handleSosChange}
                        />
                      </div>
                      <div>
                        {formData.sos ? (
                          <select
                            name='instructions'
                            value={formData.instructions}
                            className='medication-input'
                            onChange={handleNameChange}
                          >
                            <option value=''>Select An Option</option>
                            {instructions.map((op: any) => (
                              <option
                                className='option-content'
                                key={op.value}
                                value={op.value}
                              >
                                {op.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        {formData.sos ? (
                          <input
                            name='additional'
                            type='textArea'
                            className='medication-input'
                            placeholder='additional instructions'
                            value={formData.additional}
                            onChange={handleNameChange}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </>
                </td>
                <td>
                  <Button
                    sx={{
                      textTransform: 'none',
                      backgroundColor: '#e9ecef',
                      fontSize: '12px !important',
                    }}
                    className='filter-button'
                    type='submit'
                    disabled={
                      sosCheck() ||
                      status === 'finished' ||
                      status === 'cancelled'
                        ? true
                        : false
                    }
                  >
                    Add
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  )
}

export default MedicationForm
