import { useRef, useEffect, useState, useCallback } from 'react'
import { FormattedPatient } from '../patients/Patients'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { startSxpProxy, sendSxpMessage, updateSxpState } from '../../utils/api'
import { getProvenanceData } from '../patients/utils'
import { useReactToPrint } from 'react-to-print'
import {
  CHAT_PROJECT_ID,
  ADMIN_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
} from '../../utils/constants'
import KeycloakService from '../../utils/keycloakService'
import {
  PATIENT_DASHBOARD_READ,
  PATIENT_DASHBOARD_WRITE,
  SXP_CHAT,
  PATIENTS_WRITE,
} from '../../utils/roles'
import {
  ProvenanceData,
  selectPatientsStatus,
  setRedirectTo,
} from '../patients/patientsSlice'
import PrintLogo from '../../assets/images/print.png'
import OPDComponentPrint from './opdCard'
import UploadDocs from '../patients/dashboard/UploadDocs'
import uploadIcon from './../../assets/images/download_uplaod.png'
import visiticon from './../../assets/images/visit.png'
import patientDashboardIcon from './../../assets/images/patientDashboardIcon.png'
import OPDVisits from './OPDVisit'
import MaskedNumber from '../../components/MaskedNumber'
import { Dialog, DialogContent, IconButton, Snackbar } from '@mui/material'
import ProvenanceView from '../../components/ProvenanceView'
import { odooPharmacyLink } from '../appointments/models'
import { selectSelectedLocationId } from '../location/locationSlice'
import { upComingAppointmentType } from './constant'
import { Provenance, Practitioner } from 'fhir/r4'
import LocationByPrefix from '../patients/dashboard/LocationByPrefix'

type Props = {
  patients: FormattedPatient[]
}

const RegistrationType = ({ regType }: { regType: string }) => {
  let text = 'Other'
  let title = 'Invalid Registration Type'

  switch (regType) {
    case 'fr':
      text = 'Full'
      title = 'Full Registration'
      break
    case 'qr':
      text = 'Quick'
      title = 'Quick Registration'
      break
    case 'er':
      text = 'ER'
      title = 'Emergency'
      break
    case 'pmc':
      text = 'Pharmacy'
      title = 'Pharmacy'
      break
    case 'lab':
      text = 'Lab'
      title = 'Laboratory'
      break
    case 'dgs':
      text = 'Diagnostics'
      title = 'Diagnostics'
      break
    case 'camp':
      text = 'Campaign'
      title = 'Campaign'
      break
    case 'membership':
    case 'membershipPrimary':
      text = 'MemberShip'
      title = 'MemberShip'
      break
    case 'package':
      text = 'Package'
      title = 'Package'
      break
  }

  return <span title={title}>{text}</span>
}

const PatientRow = ({
  d,
  provenances,
  index,
  isOpenDocsPage,
  handleDocClick,
}: {
  d: FormattedPatient
  provenances: Record<string, ProvenanceData>
  index: number
  onCreate: () => void
  onEdit: () => void
  isOpenDocsPage: boolean
  handleDocClick: (patientId: string) => void
}) => {
  const registrationRef = useRef(null)
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    upComingAppointmentType[]
  >([])
  const [appointmentIds, setAppointmentIds] = useState<string[]>([])
  const handleRegistrationPrint = useReactToPrint({
    content: () => registrationRef.current,
  })
  const [showAlert, setShowAlert] = useState(false)
  const locationId = useAppSelector(selectSelectedLocationId)
  const [encounterDetails, setEncounterDetails] = useState<any>(null)
  const openOdooInvoice = (id: string) => {
    startSxpProxy(ADMIN_PROJECT_ID, odooPharmacyLink, {
      invoiceId: id,
      location: locationId,
    })
      .then((data) => {
        const url = data?.data?.auth_link
        window.open(url, '_blank', 'noopener,noreferrer')
      })
      .catch((err) => console.error(err, 'err'))
  }
  const details: any = {
    name: d.name,
    id: d.id,
    Age: d.age,
    gender: d.gender,
    Mobile: d.mobile,
    RegisteredOn: d.registeredOn,
  }

  const navigate = useNavigate()
  const showvisit = (
    patientId: string,
    encounterId: string,
    visitType: string
  ) => {
    let basePath = `/visits/${encounterId}/history`
    if (visitType === 'dental') {
      basePath = `/visits/dental/${encounterId}/historyDental`
    } else if (visitType === 'physio') {
      basePath = `/visits/physio/${encounterId}/historyPhysio`
    }

    navigate(basePath, {
      state: {
        id: patientId,
        show: true,
      },
    })
  }

  const fetchAllAppointments = async (patientId: string) => {
    try {
      const intent = 'allAppointments'
      const state = {
        id: patientId,
      }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)

      if (resp?.data?.length === 0) {
        setUpcomingAppointments([])
      } else {
        setUpcomingAppointments(resp?.data)
        const ids = resp?.data?.map(
          (appointment: { appointmentId: string }) => appointment?.appointmentId
        )
        setAppointmentIds(ids)
      }
    } catch (e) {
      console.error('Error fetching upcoming appointments:', e)
    }
  }

  const fetchEncounterForAppointments = useCallback(
    async (appointmentIdsString: string) => {
      try {
        const intent = 'getEncounterForAppointments'
        const state = { appointmentIds: appointmentIdsString }
        const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
        setEncounterDetails(resp?.data)
      } catch (e) {
        console.error('Error fetching encounter details:', e)
      }
    },
    []
  )
  useEffect(() => {
    if (d?.fhirId) {
      fetchAllAppointments(d.fhirId)
    }
  }, [d])

  useEffect(() => {
    if (appointmentIds.length > 0) {
      const appointmentIdsString = appointmentIds.join(',')
      fetchEncounterForAppointments(appointmentIdsString)
    }
  }, [appointmentIds, fetchEncounterForAppointments])
  const handleVisitClick = () => {
    if (encounterDetails?.[0]?.encounterId) {
      showvisit(
        d.fhirId,
        encounterDetails?.[0]?.encounterId,
        encounterDetails.visitType
      )
    } else {
      setShowAlert(true)
    }
  }

  const handleNameClick = (patientId: string) => {
    navigate('/opd', { state: { fhirId: d?.fhirId, MUHId: d?.id } })
  }

  return (
    <tr>
      <td className='text-center'>{index + 1}</td>
      <td>{d.id || d.fhirId}</td>
      <td style={{ fontSize: '10px' }}>
        <RegistrationType regType={d.registrationType} />
      </td>
      <td>
        {KeycloakService.hasRole([
          PATIENT_DASHBOARD_READ,
          PATIENT_DASHBOARD_WRITE,
        ]) ? (
          <NavLink to={`/patient-dashboard/${d.fhirId}`}>{d.name}</NavLink>
        ) : (
          d.name
        )}
        &nbsp;
        {d?.invoiceId && d?.invoiceId !== 'unpaid' && (
          <span
            className='OPDScreenInvoice'
            onClick={() => openOdooInvoice(d.invoiceId)}
          >
            Unpaid
          </span>
        )}
      </td>
      <td>
        <MaskedNumber mobile={d?.mobile} />
      </td>
      <td className='text-center'>{d?.gender}</td>
      <td className='text-center' title={d?.age}>
        {d?.age}
      </td>
      <td className='text-center'>
        <LocationByPrefix id={d?.id} />
      </td>
      <td className='text-center'>
        <ProvenanceView
          date={d?.registeredOn}
          name={provenances?.[d?.fhirId]?.createdBy}
          mode={'CREATE'}
        />
      </td>
      <td className='text-center'>
        <ProvenanceView
          date={provenances?.[d?.fhirId]?.updatedOn ?? d.registeredOn}
          name={
            provenances?.[d?.fhirId]?.updatedBy ??
            provenances?.[d?.fhirId]?.createdBy
          }
          mode={'UPDATE'}
        />
      </td>
      <td>
        <div className={`uploadDocBtns ${isOpenDocsPage ? 'active' : ''}`}>
          <div
            onClick={() => handleDocClick(d.id)}
            className='uploadbtn'
            style={{ textAlign: 'center' }}
          >
            <img
              title='Upload Document`s'
              src={uploadIcon}
              alt='upload button'
              className='text-center'
            />
          </div>
        </div>
      </td>
      <td className='text-center appointmentBtn'>
        <div className='printButtons'>
          <div style={{ display: 'none' }}>
            <OPDComponentPrint ref={registrationRef} patient={details} />
          </div>
          <div style={{ display: 'flex', gap: '1rem', marginTop: '0.3rem' }}>
            <div onClick={() => handleRegistrationPrint()} className='printbtn'>
              <img
                title='Registration Card'
                src={PrintLogo}
                alt='Print Registration Card'
              />
            </div>
            <div className='printbtn'>
              <div className='printbtn'>
                <img
                  title='Visits'
                  src={visiticon}
                  alt='Visits'
                  onClick={handleVisitClick}
                />
                <Snackbar
                  open={showAlert}
                  autoHideDuration={3000}
                  onClose={() => setShowAlert(false)}
                  message='No visits'
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />
              </div>
            </div>
            <div className='printbtn'>
              <img
                title='DashBoard'
                src={patientDashboardIcon}
                alt='DashBoard'
                style={{ height: '20px' }}
                onClick={() => handleNameClick(d?.id)}
              />
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
}

const OPDPatientTable = ({ patients }: Props & { isOpenDocsPage: boolean }) => {
  const status = useAppSelector(selectPatientsStatus)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const ids = patients.map((p) => p.fhirId).join(',')
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})

  const handleCloseDialog = () => {
    setIsOpenDocsPage(false)
  }

  const createAppointment = async (d: FormattedPatient) => {
    if (KeycloakService.hasRole([SXP_CHAT])) {
      await sendSxpMessage(CHAT_PROJECT_ID, '::restart')
      const state = {
        patientId: d.fhirId,
        identifier: d.id,
      }
      await updateSxpState(CHAT_PROJECT_ID, state)
      window.chatSendMsg('bookAppointmentFromUI', KeycloakService.getUsername())
    }
  }

  const editPatient = (d: FormattedPatient) => {
    if (KeycloakService.hasRole([PATIENTS_WRITE])) {
      dispatch(setRedirectTo('patients'))
      navigate(`/patient-registration/${d.fhirId}`)
    }
  }

  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const [isOpenDocsPage, setIsOpenDocsPage] = useState(false)

  const handleDocClick = () => {
    setIsOpenDocsPage((prevState) => !prevState)
  }
  const location = useLocation()
  const isOpdPath = location.pathname === '/opd'
  const patientId = patients[0]?.fhirId
  useEffect(() => {
    if (ids) {
      startSxpProxy(REGISTRATION_PROJECT_ID, 'fetchProvenance', {
        patientIds: ids,
      })
        .then((data) => {
          const entries =
            data?.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde?.resource
            ) ?? []
          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        .catch((err) => console.error(err))
    }
  }, [ids])
  return (
    <>
      <div
        className='fixTableHead'
        ref={patientTableContainerRef}
        style={{ marginBottom: '1rem' }}
      >
        <table className='data-table table-fixed admin-table'>
          <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
            <tr>
              <th className='table-w-4'>Sl&nbsp;No</th>
              <th className='text-left table-w-15'>UHID</th>
              <th className='table-w-8'>Reg.Type</th>
              <th className='text-left table-w-15'>Name</th>
              <th className='table-w-8'>Mobile No.</th>
              <th className='text-center table-w-6'>Gender</th>
              <th className='text-center table-w-8'>Age</th>
              <th className='text-center table-w-10'>Operating Unit</th>
              <th className='text-center table-w-10'>Created On</th>
              <th className='text-center table-w-10'>Updated On</th>
              <th className='text-center table-w-10'>Patient Doc&lsquo;s</th>
              <th className='text-center table-w-10'>View</th>
            </tr>
          </thead>
          <tbody>
            {patients?.length === 1 ? (
              patients?.map((d, i) => (
                <PatientRow
                  key={d?.fhirId}
                  d={d}
                  provenances={provenances}
                  index={i}
                  onCreate={() => createAppointment(d)}
                  onEdit={() => editPatient(d)}
                  isOpenDocsPage={isOpenDocsPage}
                  handleDocClick={handleDocClick}
                />
              ))
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: 'center' }}>
                  {status === 'loading'
                    ? 'Loading Patient Data...'
                    : 'No patients found'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Dialog open={isOpenDocsPage} onClose={handleCloseDialog}>
        <IconButton
          onClick={handleCloseDialog}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          X
        </IconButton>
        <DialogContent style={{ marginTop: '-2rem' }}>
          <UploadDocs patientId={patientId} />
        </DialogContent>
      </Dialog>
      <div
        className='opd-visit-section scrollable-section'
        ref={patientTableContainerRef}
      >
        {isOpdPath && patients?.length === 1 && <OPDVisits id={patientId} />}
      </div>
    </>
  )
}

export default OPDPatientTable
